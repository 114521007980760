<template>
    <v-dialog v-model="factoryResetDialog" width="700">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="w-100">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>Factory reset</v-card-title>
            <v-card-text class="pt-3" v-if="!failure">
                <v-alert outlined type="error">
                    <p>
                        Factory reset will stop this application for all users and clear all data installed into the application's
                        <b>LIB</b>
                        area.
                    </p>
                    These are typically:
                    <ul>
                        <li>software packages (Python, R, LaTeX, etc.)</li>
                        <li>applicaton-level configurations</li>
                        <li>applicaton add-on data (e.g. database files for MariaDB or Redis add-ons)</li>
                    </ul>
                </v-alert>
                <v-alert outlined type="info">
                    Factory reset
                    <b>WILL NOT</b>
                    affect the following files:
                    <ul>
                        <li>application configuration and data files in your HOME are (Linux home ~)</li>
                        <li>data in you FILES area (Workspace files)</li>
                    </ul>
                </v-alert>
                <v-card flat outlined>
                    <v-checkbox
                        color="primary"
                        v-model="autobackUp"
                        label="Take a snapshot before Factory reset"
                        hide-details
                        class="ma-0 pa-4"
                        :disabled="resettingApp"></v-checkbox>
                </v-card>
            </v-card-text>
            <v-card-text v-else>
                <dashboard-error v-if="failure" msg="Something went wrong, please try again later!" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="factoryResetDialog = false" :disabled="resettingApp">Back</v-btn>
                <v-btn color="primary" text @click="resetApp()" :loading="resettingApp" v-if="!success && !failure">Reset</v-btn>
                <v-btn color="success" text v-if="success"><v-icon>mdi-check</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
const DashboardError = () => import('@/components/DashboardError.vue')

export default {
    name: 'FactoryReset',
    components: { DashboardError },
    data() {
        return {
            factoryResetDialog: false,
            resettingApp: false,
            autobackUp: false,
            success: false,
            failure: false
        }
    },
    props: {
        app: { type: Object, required: true }
    },
    methods: {
        async resetApp() {
            this.resettingApp = true
            try {
                await this.$axios.post(`/applications/${this.app.aid}/factory_reset`, {
                    auto_backup: this.autobackUp
                })
                this.success = true
                await new Promise(resolve => setTimeout(() => resolve(), 2000))
                this.factoryResetDialog = false
            } catch (error) {
                this.failure = true
            }
            this.resettingApp = false
        }
    },
    watch: {
        factoryResetDialog(open) {
            if (open) {
                this.resettingApp = false
                this.autobackUp = false
                this.success = false
                this.failure = false
            }
        }
    }
}
</script>
